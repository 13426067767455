$primary: #419468;
$primary-hover: #59BCB7;
$secondary: #78FAAE;
$secondary-hover: #ABFFCC;
$tertiary: #0E3A2F;
$tertiary-hover: #1F5044;

$success-color-border: #37D002;
$info-color-border: #2845E8;
$warning-color-border: #AB8A13;
$danger-color-border: #E82837;

$success-color-background: #F4F9EB;
$info-color-background: #E6EEFF;
$warning-color-background: #FEF9E8;
$danger-color-background: #FFF2F0;

$text-color: #161718;

$white: #ffffff;
$black: #000000;

$border-color: #dcdcdc;

$background-primary: $white;
$background-secondary: #f8f8f8;


$background-disabled: #d8d8d8;
$text-disabled-color: #9e9fa0;