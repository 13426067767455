/* Variables from Automotive Portal */

@import './home';
@import './icons';
@import './validation';
@import './fonts.scss';
@import './colors.scss';

//Webshop variables
$brand-background-color: $tertiary;
$footer-background-color: $tertiary;

$sidebar-text-colour: $text-color;
$sidebar-active-item-text-colour: $primary;
$sidebar-active-item-vertical-line-colour: $primary;
$sidebar-background: $background-primary;
$sidebar-inactive-item-background: $background-disabled;
$sidebar-inactive-item-text-color: $text-disabled-color;

$cookie-background-color: $tertiary;

$tab-non-active-text-color: $text-color;
$tab-active-text-color: $text-color;
$tab-alignment: center;
$tab-text-transform: none;

/* Form-control */
$field-color: $text-color;
$field-background-color: $white;
$field-border-radius: 0.25rem;
$field-border-color: $border-color;
$field-border-width: 1px;
$field-font-size: 1rem;
$field-disabled-text-color: $text-disabled-color;

$bottom-delimiter-color: $border-color;
$plan-delimiter: $border-color;

$required-fields-text-color: $text-disabled-color;

/* Header Logo */
$logo-margin-top: 0.0rem;

$step-container-bg: $black;
$step-active-badge-bg: $secondary;
$step-active-text-color: $black;

$step-inactive-badge-bg: $white;
$step-inactive-text-color: $black;
$step-border-color: $white;

$step-item-name-text-color: $white;

$vehicle-scan-bg: linear-gradient(180deg, #394748 2.15%, $black 100%);

$icon-circle-dark: #6F797A;
$icon-circle-light: #E5E7EB;
$vehicle-icon-bg: #6F797A;

$scan-line-bg: $secondary;
$pinpoint-bg: $primary;
$pinpoint-circle-bg: $secondary;

$vehicle-status-compatible-bg: #7DE05B;
$vehicle-status-incompatible-bg: #F16A6A;
$vehicle-status-text-color: $text-color;

$footer-text-color: $white;
$footer-text-color-hover: #d9d9d9;

//Bootstrap variables
$btn-border-radius: 6.67rem;
$btn-font-weight: 600;
$nav-tabs-link-active-bg: transparent;

/* Switch Button variables */
$switch-btn-checked-color: $primary;

//Login in Partnership with Logo
$partnershipBrandLogo: '/';