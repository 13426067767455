@import 'variables.scss';

$theme-colors: (
    "primary": $primary,
    "secondary": $tertiary,
    "success": $success-color-border,
    "info": $info-color-border,
    "warning": $warning-color-border,
    "danger": $danger-color-border,
    "light": $white,
    "dark": $black
);

//Cutomize the buttons because skoda has two primary colors, which one is specific for buttons only
.btn {
    &.btn-primary {
        background-color: $secondary;
        border-color: $secondary;
        color: $text-color !important;

        &:hover {
            background-color: $secondary-hover;
            border-color: $secondary-hover;
        }

        &:active,
        &:focus,
        &:not(:disabled):not(.disabled):active {
            background-color: $secondary;
            border-color: $secondary;
            box-shadow: 0px 0px 0px 3.2px transparentize($secondary-hover, .5) !important;
        }

        &:disabled,
        &.disabled {
            background-color: $background-disabled !important;
            border-color: $background-disabled !important;
            color: $text-disabled-color !important;
            box-shadow: none !important;
            opacity: 1 !important;
        }
    }

    &.btn-secondary,
    &.btn-outline-secondary {
        background-color: transparent;
        border-color: $text-color;
        color: $text-color !important;

        &:hover {
            background-color: rgba(22, 23, 24, .06);
            border-color: $text-color;
        }

        &:active,
        &:focus,
        &:not(:disabled):not(.disabled):active {
            background-color: rgba(22, 23, 24, .06);
            border-color: $text-color;
            box-shadow: 0px 0px 0px 3.2px transparentize($text-color, .5) !important;
        }

        &:disabled,
        &.disabled {
            background-color: #d8d8d8 !important;
            border-color: #d8d8d8 !important;
            color: #9e9fa0 !important;
            box-shadow: none !important;
            opacity: 1 !important;
        }
    }
}

// .partnership-text-center {
//     display: none !important;
// }

// .partnershipLogo {
//     background-position: 50% 35%;
//     margin: 1px;
//     padding: 0px;

// }